import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import moment from "moment";
import { Icon } from "@iconify/react";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Flag from "./Flag";
import {
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Dialog,
  Checkbox,
} from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";

export default function PredictGoalPopUp({ handleClose, selectedMatch }) {
  const [checked, setChecked] = React.useState(false);

  const [open, setOpen] = useState(true);
  const goalRegExp = "^[0-9]+$";

  const predictSchema = Yup.object().shape({
    firstTeamGoals: Yup.string().matches(goalRegExp).required("This field is required"),
    secondTeamGoals: Yup.string().matches(goalRegExp).required("This field is required"),
    fullname: Yup.string().required("Fullname is required"),
    checked: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
    mobileNumber: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      countryId: "",
      fullname: "",
      mobileNumber: "",
      firstTeamGoals: "",
      secondTeamGoals: "",
      checked: false,
    },
    validationSchema: predictSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/poll/user`, {
          ...values,
          matchId: selectedMatch._id,
          countryId: values.countryId === "DRAW" ? null : values.countryId,
        });
        if (!res.data.isError) {
          // setOpen(false);
          toast.success("Poll Success");
          handleClose();
        } else {
          toast.error(res.data.message);
        }
        // getMatch();
        resetForm();
      } catch (error) {
        // console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;

  const handleChange = (event) => {
    setChecked(event.target.checked);
    setFieldValue("checked", event.target.checked);
  };
  return (
    <div>
      {/* predict popup */}
      {open && (
        <Dialog
          open={open}
          PaperProps={{
            style: { borderRadius: "10px 10px 5px  5px" },
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container sx={{ minWidth: "250px" }}>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      background:
                        "linear-gradient(180deg, #1524E0 0%, #2D5AF1 51.04%, #0006D8 100%)",
                      color: "white",
                    }}
                    paddingX={1}
                    paddingY={0.8}
                  >
                    <Stack
                      sx={{ width: "100%", marginRight: "-19.2px", fontSize: "15px" }}
                      textAlign="center"
                    >
                      {moment(selectedMatch.matchDate).format("ddd, DD MMM h:mm a")}
                    </Stack>
                    <Stack textAlign="center">
                      <Icon
                        icon="material-symbols:close"
                        style={{ cursor: "pointer" }}
                        onClick={handleClose}
                      />
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} mt={4} mb={3}>
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    spacing={{ lg: 5, md: 5, sm: 4, xs: 3 }}
                  >
                    <Stack
                      spacing={1}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flag flag={selectedMatch?.firstTeam?.countryFlag} />
                      <Typography variant="body2">{selectedMatch?.firstTeam?.name}</Typography>
                    </Stack>
                    <Stack spacing={1}>
                      <Typography variant="body2">Vs</Typography>
                    </Stack>
                    <Stack
                      spacing={1}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Flag flag={selectedMatch?.secondTeam?.countryFlag} />
                      <Typography variant="body2">{selectedMatch?.secondTeam?.name}</Typography>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item xs={12} mb={4}>
                  <Stack
                    spacing={1}
                    sx={{ width: "100%" }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack
                      spacing={2}
                      sx={{ width: "260px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TextField
                        type="text"
                        size="small"
                        label="Fullname"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon icon="healthicons:ui-user-profile" />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: "100%" }}
                        {...getFieldProps("fullname")}
                        error={Boolean(touched.fullname && errors.fullname)}
                        helperText={touched.fullname && errors.fullname}
                      />
                      <TextField
                        label="Email"
                        type="email"
                        size="small"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon icon="material-symbols:mail-rounded" />
                            </InputAdornment>
                          ),
                        }}
                        sx={{ width: "100%" }}
                        {...getFieldProps("mobileNumber")}
                        error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                        helperText={touched.mobileNumber && errors.mobileNumber}
                      />

                      <Stack sx={{ width: "100%" }} justifyContent="center" alignItems="center">
                        <Typography variant="body2">Final Score</Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        sx={{ width: "100%" }}
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                      >
                        <TextField
                          type="text"
                          size="small"
                          label={`${selectedMatch.firstTeam.countryCode} Goals`}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon icon="ion:football" />
                              </InputAdornment>
                            ),
                          }}
                          sx={{ width: "100%" }}
                          {...getFieldProps("firstTeamGoals")}
                          error={Boolean(touched.firstTeamGoals && errors.firstTeamGoals)}
                          // helperText={touched.firstTeamGoals && errors.firstTeamGoals}
                        />
                        <TextField
                          type="text"
                          size="small"
                          label={`${selectedMatch.secondTeam.countryCode} Goals`}
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon icon="ion:football" />
                              </InputAdornment>
                            ),
                          }}
                          sx={{ width: "100%" }}
                          {...getFieldProps("secondTeamGoals")}
                          error={Boolean(touched.secondTeamGoals && errors.secondTeamGoals)}
                          // helperText={touched.secondTeamGoals && errors.secondTeamGoals}
                        />
                      </Stack>
                      <Stack>
                        {((touched.secondTeamGoals && errors.secondTeamGoals) ||
                          (touched.firstTeamGoals && errors.firstTeamGoals)) && (
                          <Typography variant="caption" color="error">
                            Invalid input
                          </Typography>
                        )}
                      </Stack>
                      <Stack
                        alignItems="center"
                        justifyContent="left"
                        spacing={2}
                        direction="row"
                        sx={{ width: "100%" }}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <a style={{textDecoration:"none"}} href="https://www.keralataste.com/pages/privacy-policy" target="__blank">
                          <Typography variant="caption">Terms and Conditions</Typography>
                        </a>
                      </Stack>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={isSubmitting || !checked}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Dialog>
      )}
      {/* predict popup ends */}
    </div>
  );
}
