import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Icon } from "@iconify/react";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="h6" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Footer() {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Box
      paddingTop={5}
      paddingBottom={4}
      sx={{
        background: "linear-gradient(180deg, #00042D 0%, #00085F 100%)",
        mixBlendMode: "darken",
      }}
      paddingX={1}
    >
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Stack justifyContent="center" direction="row" alignItems="center" mt={0.5}>
            <Stack
              justifyContent="space-between"
              direction="column"
              alignItems={{ xs: "center", sm: "center" }}
              spacing={1}
              sx={{ maxWidth: { xs: "80%", md: "250px" } }}
            >
              <img src="/static/images/kt-logo.png" alt="logo" style={{ width: "150px" }} />
              <Stack
                justifyContent="center"
                direction="column"
                alignItems={{ xs: "center", sm: "start" }}
              >
                <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                  Kerala Taste is an online Indian grocery store in the UK especially for those who love Kerala cuisine and Kerala Traditions.
                </Typography>
                {/* <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                  GOVT. CYBERPARK Nellikode PO,
                </Typography>
                <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                  Kozhikode-673016
                </Typography> */}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Stack justifyContent="center" direction="row" alignItems="center">
            <Stack
              justifyContent="center"
              direction="column"
              alignItems={{ xs: "center", sm: "center" }}
              spacing={1}
              sx={{ maxWidth: { xs: "270px", md: "250px", lg: "350px" } }}
            >
              <Typography sx={{ color: "white" }} variant="h6">
                How it works?
              </Typography>
              <Stack
                justifyContent="center"
                direction="column"
                alignItems={{ xs: "center", sm: "start" }}
              >
                <Typography
                  textAlign={{ xs: "center", sm: "start" }}
                  variant="caption"
                  sx={{ color: "white", fontSize: "11px" }}
                >
                  Users will get 24 hours to predict the winning team before the match starts.
                  Winners of the prediction will be announced once the match has concluded. Exciting
                  prizes await the winners.{" "}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Stack justifyContent="center" direction="row" alignItems="center">
            <Stack
              justifyContent="center"
              direction="column"
              alignItems={{ xs: "center", sm: "center" }}
              spacing={1}
              sx={{ maxWidth: { xs: "250px", sm: "400px", md: "250px", lg: "250px" } }}
            >
              <Typography sx={{ color: "white" }} variant="h6">
                Contact
              </Typography>
              <Stack
                justifyContent="center"
                direction={{ xs: "column", sm: "column", md: "column", lg: "column" }}
                alignItems={{ xs: "center", md: "start" }}
                spacing={0.3}
              >
                <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
                  <Icon style={{ color: "white" }} icon="icomoon-free:mail4" />
                  <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                    info@keralataste.com
                  </Typography>
                </Stack>
                <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
                  <Icon style={{ color: "white" }} icon="el:phone-alt" />
                  <Stack>
                    <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                      03003034715
                    </Typography>
                    {/* <Typography variant="caption" sx={{ color: "white", fontSize: "11px" }}>
                      + 91 9072 863 636
                    </Typography> */}
                  </Stack>
                </Stack>
                <Stack
                  justifyContent="center"
                  direction="column"
                  sx={{ width: "100%" }}
                  alignItems={{ xs: "center", sm: "center", md: "end" }}
                >
                  <Stack direction="row" justifyContent="right" spacing={0.6} alignItems="center">
                    {/* <Tooltip title="stackroots.in">
                      <Stack
                        onClick={() => openInNewTab("https://www.stackroots.in/")}
                        sx={{
                          borderRadius: "50%",
                          height: "14px",
                          width: "14px",
                          textAlign: "center",
                          backgroundImage: 'url("/static/images/icon.png")',
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          margin: 0,
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip> */}
                    {/* 
                    <Icon
                      onClick={() =>
                        openInNewTab(
                          "https://www.linkedin.com/company/stackroots-technology-solutions"
                        )
                      }
                      style={{ color: "white", cursor: "pointer" }}
                      icon="mdi:linkedin"
                    /> */}
                    <Icon
                      style={{ color: "white", cursor: "pointer" }}
                      icon="ic:baseline-facebook"
                      onClick={() => openInNewTab("https://www.facebook.com/Keralatasteonline/")}
                    />
                    <Icon
                      style={{ color: "white", cursor: "pointer" }}
                      icon="uil:instagram-alt"
                      onClick={() =>
                        openInNewTab("https://www.instagram.com/keralatasteonline/")
                      }
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
